module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"wordpress":[{"protocol":"https","domain":"nodustfs.lignostar.com","language":"nl","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]},{"protocol":"https","domain":"nodustfs.lignostar.com","language":"en","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]},{"protocol":"https","domain":"nodustfs.lignostar.com","language":"fr","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]},{"protocol":"https","domain":"nodustfs.lignostar.com","language":"de","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]},{"protocol":"https","domain":"nodustfs.lignostar.com","language":"es","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NoDustFS.com","short_name":"NoDustFS","start_url":"/?pwa=true","background_color":"black","theme_color":"#3d97b7","display":"minimal-ui","icon":"src/images/favicon.png","lang":"nl","localize":[{"start_url":"/en/","lang":"en"},{"start_url":"/fr/","lang":"fr"},{"start_url":"/de/","lang":"de"},{"start_url":"/es/","lang":"es"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a62a82b0d60b57ccbae64913b4fa0b6e"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-SE71XEPVKM","UA-36988470-4"],"pluginConfig":{"head":false,"respectDNT":true,"anonymize_ip":true,"exclude":[]}},
    }]
